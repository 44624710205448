body {
    background-color: #151515;
}

.full-screen-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-box {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 20px;
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    height: calc(100vh - 20%);
}

.huge-text {
    font-family: 'Roboto', sans-serif;
    font-size: 5vw;
    line-height: 10vw;
    text-transform: uppercase;
    letter-spacing: 1vw;

    color: transparent; /* Make the text color transparent */
    background: linear-gradient(230deg, #ff0000, #00ff00, #0000ff);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: Gradient 6s ease infinite;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text-align: center;
}

@media (max-width: 768px) {
    .huge-text {
        font-size: 1.7rem;
        line-height: 3.6rem;
        letter-spacing: 0.5rem;
    }
}


@keyframes Gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
